import { CircularProgressbar } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import { Space, Card, Tooltip } from "antd";

import { ProgressBarLine } from 'react-progressbar-line';
import { getTrimester, stringAmount } from "../../utils/utils";
import './style.css'
import { CheckCircleOutlined, CloseCircleOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import Title from "antd/es/typography/Title";
import { hostApi } from "../../config";
import { navigate } from "@reach/router";
import { value } from "lodash/seq";

// const year = 2024
// const beforeYear = 2023
const percentageOk = 0;
const percentageTop = 20;

const parseTrimesterResponse = (t) => {
  //Parse to order month
  if(t.trimester === 1) {
    t.value = {
      Enero: t.value.Enero,
      Febrero: t.value.Febrero,
      Marzo: t.value.Marzo,
    }
  } else if(t.trimester === 2) {
    t.value = {
      Abril: t.value.Abril,
      Mayo: t.value.Mayo,
      Junio: t.value.Junio,
    }
  } else if(t.trimester === 3) {
    t.value = {
      Julio: t.value.Julio,
      Agosto: t.value.Agosto,
      Septiembre: t.value.Septiembre,
    }
  } else if(t.trimester === 4) {
    t.value = {
      Octubre: t.value.Octubre,
      Noviembre: t.value.Noviembre,
      Diciembre: t.value.Diciembre,
    }
  }

  return t;
}

const initModelTrimester = (trimester=1) => {
  return {
    trimester,
    show: false,
    percentage: 0,
    value: 0,
    months: {},
    objective: 0,
     objectiveOk: 0,
    objectiveCompleted: false,
    objectiveOkCompleted: false,
  }
}

const calculateAnnual = (trimester) => {

  let value = 0
  let percentage = 0;
  let objective = 0
  let objectiveOk = 0;
  trimester.forEach(t => {
    value += t.value;
    percentage += t.percentage;
    objective += t.objective;
    objectiveOk += t.objectiveOk;
  })

  percentage = Math.round(value*10000/objective)/100;

  return {
    value,
    percentage,
    objective,
    objectiveOk,
    objectiveCompleted: objective - value < 0,
    objectiveOkCompleted: objectiveOk - value <0
  };
}

const calculateTrimester = (apiTrimester, apiBeforeTrimester) => {

  let trimesterObjective = 0;
  let trimesterObjectiveOk = 0;

  const trimesters = [
    initModelTrimester(1),
    initModelTrimester(2),
    initModelTrimester(3),
    initModelTrimester(4),
  ];

  apiTrimester.forEach((t, index) => {

    trimesterObjective = 0;
    trimesterObjectiveOk = 0;
    trimesters[index].months = t.value;
    Object.entries(trimesters[index].months).forEach(v => {
      const amountMonthBeforeYear = apiBeforeTrimester[index].value[v[0]];
      const monthObjective = amountMonthBeforeYear * (1 + (percentageTop/100));
      const monthObjectiveOk = amountMonthBeforeYear * (1 + (percentageOk/100));
      trimesters[index].months[v[0]] = {
        percentage: Math.round(100 * v[1] / monthObjective),
        value: v[1],
        objective: monthObjective,
        objectiveOk: monthObjectiveOk,
        objectiveCompleted: monthObjective - v[1] < 0,
        objectiveOkCompleted: monthObjectiveOk - v[1] < 0,
      };
      trimesters[index].value += v[1];
      trimesterObjective += monthObjective;
      trimesterObjectiveOk += monthObjectiveOk;
    })
    trimesters[index].percentage = Math.round(100 * trimesters[index].value / trimesterObjective);
    trimesters[index].objective = trimesterObjective;
    trimesters[index].objectiveOk = trimesterObjectiveOk;
    trimesters[index].objectiveCompleted = trimesterObjective - trimesters[index].value < 0
    trimesters[index].objectiveOkCompleted = trimesterObjectiveOk - trimesters[index].value < 0
  })

  return trimesters;
}

const exampleTrimester = [{
  trimester: 1,
  show: false,
  percentage: 0,
  months: {
    Enero: {
      percentage: 0,
      value: 0,
    },
    Febrero: {
      percentage: 0,
      value: 0,
    },
    Marzo: {
      percentage: 0,
      value: 0,
    },
  }
},
  {
    trimester: 2,
    show: false,
    percentage: 0,
    months: {
      Abril: {
        percentage: 0,
        value: 0,
      },
      Mayo: {
        percentage: 0,
        value: 0,
      },
      Junio: {
        percentage: 0,
        value: 0,
      },
    }
  },
  {
    trimester: 3,
    show: false,
    percentage: 0,
    months: {
      Julio: {
        percentage: 0,
        value: 0,
      },
      Agosto: {
        percentage: 0,
        value: 0,
      },
      Septiembre: {
        percentage: 0,
        value: 0,
      },
    }
  },
  {
    trimester: 4,
    show: false,
    percentage: 0,
    months: {
      Octubre: {
        percentage: 0,
        value: 0,
      },
      Noviembre: {
        percentage: 0,
        value: 0,
      },
      Diciembre: {
        percentage: 0,
        value: 0,
      },
    }
  },
];

const TextTooltip = (value={}, disableTooltip) => {
  const [hiddenObjective, setHiddenObjective] = useState('none');

  return (
    disableTooltip ? null :
      <div>
        <p>Objetivo: { stringAmount(Math.round(value.objective)).concat('€') }</p>
        <p>Estado: <b><span style={value.objectiveCompleted ? { color: 'magenta' } : value.objectiveOkCompleted ? {color: 'greenyellow'} : {color: 'red'}}> {value.objectiveCompleted ? '+' : '-'} {stringAmount(Math.abs(Math.round(value.objective - value.value))).concat('€')}</span></b></p>
        <button
          style={{ display: !hiddenObjective.length ? 'none' : '' }}
          className={"button_more_info"}
          onClick={() => setHiddenObjective('')}>
          +
        </button>

        <p style={{display: hiddenObjective }}>Año anterior: { stringAmount(Math.round(value.objectiveOk)).concat('€') }</p>
      </div>
  )
}



const WidgetObjective = ({ title, value, disableTooltip }) => (
  <Space align="center" direction="vertical">
    <Tooltip placement="bottom" title={TextTooltip(value, disableTooltip)}>
    <div style={{ width: 100, height: 100 }}>
      <CircularProgressbar
        className={value.objectiveCompleted ? 'objective-completed' : (value.objectiveOkCompleted ? 'objectiveOk-completed' : '')}
        value={value.percentage}
        text={`${value.percentage}%`}
      />
    </div>
    </Tooltip>
    <p>{title}</p>
  </Space>
);

const CompletedOrFailed = ({ value }) => {
  console.log(value);
  if(value.objectiveCompleted) {
    return <CheckCircleOutlined className="icon-completed" />
  } else if(value.objectiveOkCompleted) {
    return <CheckCircleOutlined className="icon-completed-ok" />;
  } else {
    return <CloseCircleOutlined className="icon-failed"/>
  }
}

const CardTrimester = ({ dataTrimester , currentTrimester, onClick}) => {

  if (dataTrimester.show || dataTrimester.trimester === currentTrimester) {

    return (
      <Card title={String(dataTrimester.trimester).concat(' Trimestre')}>
        <Space size={50}>
          {Object.entries(dataTrimester.months).map(value => (<WidgetObjective title={value[0]} value={value[1]}/>))}
        </Space>
        <Tooltip placement="bottom" title={TextTooltip(dataTrimester)}>
          <div>
            <ProgressBarLine
              value={dataTrimester.percentage}
              strokeWidth={3}
              trailWidth={3}
              text={`${dataTrimester.percentage}%`}
            />
          </div>
        </Tooltip>
      </Card>
    );
  } else {
    return (
      <Card
        title={String(dataTrimester.trimester).concat(' Trimestre')}
        className={dataTrimester.trimester === currentTrimester ? '' : 'dark'}
        onClick={() => onClick(dataTrimester.trimester)}
      >
        <Space size={50} align={"center"}>
          { dataTrimester.trimester > currentTrimester ?
              <EllipsisOutlined className="icon-waiting" />
            : <CompletedOrFailed value={dataTrimester} />
          }
        </Space>
      </Card>
    );
  }
}

export default function Objective(props) {
  const { year } = props;
  const beforeYear = year-1;
  const [trimester, setTrimester] = useState(exampleTrimester);
  const [annualValue, setAnnualValue] = useState({
    percentage: 0,
    value: 0,
    objective: 180000,
    objectiveOk: 160000
  });
  const [load, setLoad] = useState(false);

  useEffect(() => {
    fetch(hostApi.concat("/invoces/clients"),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token')
        },
        body: JSON.stringify({
          start_date: year + '-01-01',
          end_date: year + '-12-31'
        })
      })
      .then(res => {
        if(res.status !== 200){
          localStorage.clear();
          navigate('/')
        } else {
          return res.json()
        }
      })
      .then(
        (result) => {
          fetch(hostApi.concat("/invoces/clients"),
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-auth-token': localStorage.getItem('token')
              },
              body: JSON.stringify({
                start_date: beforeYear + '-01-01',
                end_date: beforeYear + '-12-31'
              })
            }).then((res) => {
              if (res.status !== 200) {
                localStorage.clear();
                navigate('/')
              } else {
                return res.json()
              }
            }).then(beforeResult => {
              setLoad(true);
              setTrimester(calculateTrimester(result.map(parseTrimesterResponse), beforeResult.map(parseTrimesterResponse)));
            })
        },
        (error) => {
          navigate('/')
        }
      )
  }, [])

  const currentTrimester = new Date().getFullYear().toString() === year ? getTrimester() : 5;

  const handleClickCard = (nTrimester) => {
    const cloneTrimester = cloneDeep(trimester);
    cloneTrimester[nTrimester-1].show = true;
    setTrimester(cloneTrimester);
  }

  useEffect(() => {
    setAnnualValue(calculateAnnual(trimester));
  }, [trimester])

  if(!load) return <></>

  return (
    <div className="site-card-wrapper">
      <Title> Objetivos  {year}</Title>
      <WidgetObjective value={annualValue} />
      <Title level={3}> {stringAmount(Math.round(annualValue.objective/1000)*1000)}€</Title>
      <Space direction="vertical">
        <Space>
          <CardTrimester dataTrimester={trimester[0]} currentTrimester={currentTrimester} onClick={handleClickCard}/>
          <CardTrimester dataTrimester={trimester[1]} currentTrimester={currentTrimester} onClick={handleClickCard}/>
        </Space>
        <Space>
          <CardTrimester dataTrimester={trimester[2]} currentTrimester={currentTrimester} onClick={handleClickCard}/>
          <CardTrimester dataTrimester={trimester[3]} currentTrimester={currentTrimester} onClick={handleClickCard}/>
        </Space>
      </Space>
    </div>

  );

}

