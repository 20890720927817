export const getTrimester = () => {
  const today = new Date();
  const quarter = Math.floor((today.getMonth() + 3) / 3);
  return quarter;
}

export const stringAmount = (amount) => {

  const amountParsed = String(amount);
  let string = '';
  let index = 0;

  for (let i = amountParsed.length - 1 ; i >= 0 ; i--) {
    string = amountParsed.charAt(i).concat(string)
    index++;
    if(index === 3 && i > 0){
      string = '.'.concat(string);
      index = 0;
    }
  }

  return string;
}
