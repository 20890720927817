import React from 'react';
import { Column } from '@ant-design/charts';

const WidgetBars = ({ data }) => {

  const config = {
    data: data,
    height:200,
    xField: 'type',
    yField: 'sales',
    color: () => {
      return '#F4664A'
    },
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    meta: {
      type: { alias: 'Gastos' },
      sales: { alias: '€' },
    },
  };
  return <Column {...config} />;
};
export default WidgetBars;