import { Form, Input, Button, Checkbox, Row, Col, Avatar } from 'antd';
import "./style.css";
import { hostApi } from "../../config";
import { navigate } from "@reach/router";
import { useState } from "react";
import { Buffer } from 'buffer';



export default function LoginDemo() {
  const [form] = Form.useForm();
  const [error, setError] = useState();
  const [remember] = useState(localStorage.getItem('remember'));

  const onFinish = (values) => {
    console.log('Success:', values);
    if (values.remember) {
      const encode = Buffer.from(JSON.stringify(values)).toString('base64');
      localStorage.setItem('remember', encode);
    }
    fetch(hostApi.concat("/login"),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: values.username,
          password: values.password
        })
      })
      .then(res => {
        if(res.status !== 200){
          setError('Credenciales no validas');
          return null;
        } else {
          setError();
          return res.json()
        }
      })
      .then(
        (result) => {
          if(result) {
            localStorage.setItem('token', result.token);
            navigate("/objective/".concat(new Date().getFullYear()))
          }
        },
        (error) => {
          console.log(error);
        }
      )
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getAvatar = () => {
    const avatar = [
      'https://api.dicebear.com/5.x/adventurer/svg?seed=Callie',
      'https://api.dicebear.com/5.x/avataaars/svg?seed=Callie',
      'https://api.dicebear.com/5.x/adventurer-neutral/svg?seed=Callie',
      'https://api.dicebear.com/5.x/adventurer/svg?seed=Oreo',
      'https://api.dicebear.com/5.x/big-smile/svg?seed=Pepper&flip=true',
      'https://api.dicebear.com/5.x/adventurer-neutral/svg?seed=Scooter',
      'https://api.dicebear.com/5.x/adventurer/svg?seed=Sassy&flip=true',
      'https://api.dicebear.com/5.x/thumbs/svg?seed=Boots',
      'https://api.dicebear.com/5.x/adventurer-neutral/svg?seed=Cleo',
      'https://api.dicebear.com/5.x/big-smile/svg?seed=Sugar',
      'https://api.dicebear.com/5.x/big-ears/svg?seed=Callie',
      'https://api.dicebear.com/5.x/adventurer-neutral/svg?seed=Mimi'
    ];
    return avatar[new Date().getMonth()];
  }

  if (remember) {
    const decode = JSON.parse(Buffer.from(remember, "base64").toString())
    form.setFieldsValue(decode);
  }

  return (
    <>
      <Row className="row-login">
        <Col span={8} />
        <Col className="avatar-custom" span={8}>
          <Avatar
            size={{ xs: 100, sm: 120, md: 120, lg: 120, xl: 120, xxl: 150 }}
            src={getAvatar()}
          />
        </Col>
      </Row>
      <Row className="row-login">
        <Col span={8} />
        <Col span={8}>
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />

            </Form.Item>
            { error ?
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <span style={{color: "red"}}> {error} </span>
              </Form.Item>
              : null}

            <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};