import { CircularProgressbar } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import { Space, Card, Tooltip, Button, Dropdown } from "antd";

import { ProgressBarLine } from 'react-progressbar-line';
import { getTrimester, stringAmount } from "../../utils/utils";
import './style.css'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  EllipsisOutlined,
  UserOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import Title from "antd/es/typography/Title";
import { hostApi } from "../../config";
import { navigate } from "@reach/router";
import { value } from "lodash/seq";
import WidgetBars from "../../Components/Widgets/Bars";

// // const year = 2024
// // const beforeYear = 2023
// const percentageOk = 0;
// const percentageTop = 20;
//
// const parseTrimesterResponse = (t) => {
//   //Parse to order month
//   if(t.trimester === 1) {
//     t.value = {
//       Enero: t.value.Enero,
//       Febrero: t.value.Febrero,
//       Marzo: t.value.Marzo,
//     }
//   } else if(t.trimester === 2) {
//     t.value = {
//       Abril: t.value.Abril,
//       Mayo: t.value.Mayo,
//       Junio: t.value.Junio,
//     }
//   } else if(t.trimester === 3) {
//     t.value = {
//       Julio: t.value.Julio,
//       Agosto: t.value.Agosto,
//       Septiembre: t.value.Septiembre,
//     }
//   } else if(t.trimester === 4) {
//     t.value = {
//       Octubre: t.value.Octubre,
//       Noviembre: t.value.Noviembre,
//       Diciembre: t.value.Diciembre,
//     }
//   }
//
//   return t;
// }
//
// const initModelTrimester = (trimester=1) => {
//   return {
//     trimester,
//     show: false,
//     percentage: 0,
//     value: 0,
//     months: {},
//     objective: 0,
//     objectiveOk: 0,
//     objectiveCompleted: false,
//     objectiveOkCompleted: false,
//   }
// }
//
// const calculateAnnual = (trimester) => {
//
//   let value = 0
//   let percentage = 0;
//   let objective = 0
//   let objectiveOk = 0;
//   trimester.forEach(t => {
//     value += t.value;
//     percentage += t.percentage;
//     objective += t.objective;
//     objectiveOk += t.objectiveOk;
//   })
//   percentage = percentage/4;
//
//   return {
//     value,
//     percentage,
//     objective,
//     objectiveOk,
//     objectiveCompleted: objective - value < 0,
//     objectiveOkCompleted: objectiveOk - value <0
//   };
// }
//
// const calculateTrimester = (apiTrimester, apiBeforeTrimester) => {
//
//   let trimesterObjective = 0;
//   let trimesterObjectiveOk = 0;
//
//   const trimesters = [
//     initModelTrimester(1),
//     initModelTrimester(2),
//     initModelTrimester(3),
//     initModelTrimester(4),
//   ];
//
//   apiTrimester.forEach((t, index) => {
//
//     trimesterObjective = 0;
//     trimesterObjectiveOk = 0;
//     trimesters[index].months = t.value;
//     Object.entries(trimesters[index].months).forEach(v => {
//       const amountMonthBeforeYear = apiBeforeTrimester[index].value[v[0]];
//       const monthObjective = amountMonthBeforeYear * (1 + (percentageTop/100));
//       const monthObjectiveOk = amountMonthBeforeYear * (1 + (percentageOk/100));
//       trimesters[index].months[v[0]] = {
//         percentage: Math.round(100 * v[1] / monthObjective),
//         value: v[1],
//         objective: monthObjective,
//         objectiveOk: monthObjectiveOk,
//         objectiveCompleted: monthObjective - v[1] < 0,
//         objectiveOkCompleted: monthObjectiveOk - v[1] < 0,
//       };
//       trimesters[index].value += v[1];
//       trimesterObjective += monthObjective;
//       trimesterObjectiveOk += monthObjectiveOk;
//     })
//     trimesters[index].percentage = Math.round(100 * trimesters[index].value / trimesterObjective);
//     trimesters[index].objective = trimesterObjective;
//     trimesters[index].objectiveOk = trimesterObjectiveOk;
//     trimesters[index].objectiveCompleted = trimesterObjective - trimesters[index].value < 0
//     trimesters[index].objectiveOkCompleted = trimesterObjectiveOk - trimesters[index].value < 0
//   })
//
//   return trimesters;
// }
//
const exampleTrimester = [{
  trimester: 1,
  show: false,
  percentage: 0,
  months: {
    Enero: {
      percentage: 0,
      value: 0,
    },
    Febrero: {
      percentage: 0,
      value: 0,
    },
    Marzo: {
      percentage: 0,
      value: 0,
    },
  }
},
  {
    trimester: 2,
    show: false,
    percentage: 0,
    months: {
      Abril: {
        percentage: 0,
        value: 0,
      },
      Mayo: {
        percentage: 0,
        value: 0,
      },
      Junio: {
        percentage: 0,
        value: 0,
      },
    }
  },
  {
    trimester: 3,
    show: false,
    percentage: 0,
    months: {
      Julio: {
        percentage: 0,
        value: 0,
      },
      Agosto: {
        percentage: 0,
        value: 0,
      },
      Septiembre: {
        percentage: 0,
        value: 0,
      },
    }
  },
  {
    trimester: 4,
    show: false,
    percentage: 0,
    months: {
      Octubre: {
        percentage: 0,
        value: 0,
      },
      Noviembre: {
        percentage: 0,
        value: 0,
      },
      Diciembre: {
        percentage: 0,
        value: 0,
      },
    }
  },
];
//
// const TextTooltip = (value={}, disableTooltip) => {
//   const [hiddenObjective, setHiddenObjective] = useState('none');
//
//   return (
//     disableTooltip ? null :
//       <div>
//         <p>Objetivo: { stringAmount(Math.round(value.objective)).concat('€') }</p>
//         <p>Estado: <b><span style={value.objectiveCompleted ? { color: 'magenta' } : value.objectiveOkCompleted ? {color: 'greenyellow'} : {color: 'red'}}> {value.objectiveCompleted ? '+' : '-'} {stringAmount(Math.abs(Math.round(value.objective - value.value))).concat('€')}</span></b></p>
//         <button
//           style={{ display: !hiddenObjective.length ? 'none' : '' }}
//           className={"button_more_info"}
//           onClick={() => setHiddenObjective('')}>
//           +
//         </button>
//
//         <p style={{display: hiddenObjective }}>Año anterior: { stringAmount(Math.round(value.objectiveOk)).concat('€') }</p>
//       </div>
//   )
// }
//
//
//
// const WidgetObjective = ({ title, value, disableTooltip }) => (
//   <Space align="center" direction="vertical">
//     <Tooltip placement="bottom" title={TextTooltip(value, disableTooltip)}>
//       <div style={{ width: 100, height: 100 }}>
//         <CircularProgressbar
//           className={value.objectiveCompleted ? 'objective-completed' : (value.objectiveOkCompleted ? 'objectiveOk-completed' : '')}
//           value={value.percentage}
//           text={`${value.percentage}%`}
//         />
//       </div>
//     </Tooltip>
//     <p>{title}</p>
//   </Space>
// );
//
// const CompletedOrFailed = ({ value }) => {
//   console.log(value);
//   if(value.objectiveCompleted) {
//     return <CheckCircleOutlined className="icon-completed" />
//   } else if(value.objectiveOkCompleted) {
//     return <CheckCircleOutlined className="icon-completed-ok" />;
//   } else {
//     return <CloseCircleOutlined className="icon-failed"/>
//   }
// }
//

const BenefitsGraphics = ({ dataTrimester }) => (
  <>
    <Space size={50}>

      <WidgetBars title="Beneficio Anual" data={dataTrimester.dataPoints}/>

      {/*{Object.entries(dataTrimester.months).map(value => (<WidgetObjective title={value[0]} value={value[1]}/>))}*/}

    </Space>
    {/*<Tooltip placement="bottom" title={TextTooltip(dataTrimester)}>*/}
    <div>
      {dataTrimester.benefits}
    </div>
    {/*</Tooltip>*/}
  </>
);

const IvaData = ({ dataTrimester }) => (
  <>
    IVA
  </>
);

const CardTrimester = ({ trimester, dataTrimester , showIva, onClick}) => {
  return (

    <Card title={String(trimester).concat(' Trimestre')} extra={<a href="#" onClick={() => onClick(trimester)}>{showIva ? 'Gráfica' : 'IVA'}</a>}>
      {showIva
        ? <IvaData />
        : <BenefitsGraphics dataTrimester={dataTrimester} />
      }

    </Card>
  );
}


const getDateTrimester = (trim) => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const trimester = trim ? trim : Math.floor((month-1)/3)+1;
  switch (trimester) {
    case 1:
      return {
        start_date: `${year}-01-01`,
        end_date: `${year}-03-31`,
      }
    case 2:
      return {
        start_date: `${year}-04-01`,
        end_date: `${year}-06-30`,
      }
    case 3:
      return {
        start_date: `${year}-07-01`,
        end_date: `${year}-09-30`,
      }
    case 4:
      return {
        start_date: `${year}-10-01`,
        end_date: `${year}-12-31`,
      }
    case 5:
      return {
        start_date: `${year}-01-01`,
        end_date: `${year}-12-31`
      }
    default:

      return {
        start_date: `${year}-01-01`,
        end_date: `${year}-03-31`,
      }
  }
};

const requestBenefits = async (setValue, trimester) => {
  await fetch(`${hostApi}/budgets/trimester`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token')
      },
      body: JSON.stringify(getDateTrimester(trimester))
    })
    .then((res) => {
      if (res.status !== 200) {
        localStorage.clear();
        navigate('/')
      } else {
        return res.json();
      }
    })
    .then(data => {
      console.log(data);
      const dataPoints = [
        { type: "Gastos", sales: data.invoice_expenses  },
        { type: "Ventas",  sales: data.invoice_sold  }
      ];
      // const percentage = calculatePercentageTrimester(res.data.invoice_expenses, res.data.invoice_sold);
      const benefits = Math.round(data.invoice_sold - data.invoice_expenses, 2);
      setValue({
        dataPoints,
        // percentage,
        benefits,
      })
    })
    .catch(err => {
      console.log(err);
    })
}

export default function Benefits(props) {
  const { year } = props;
  const beforeYear = year-1;
  const [trimester, setTrimester] = useState(exampleTrimester);
  const [annualValue, setAnnualValue] = useState({

  });
  const [trimesterFirstValue, setTrimesterFirstValue] = useState({});
  const [trimesterSecondValue, setTrimesterSecondValue] = useState({});
  const [trimesterThirdValue, setTrimesterThirdValue] = useState({});
  const [trimesterFourthValue, setTrimesterFourthValue] = useState({});
  const [ivaShow, setIvaShow] = useState([false,false,false,false,false]);

  const [load, setLoad] = useState(false);

  // useEffect(() => {
  //   fetch(hostApi.concat("/invoces/clients"),
  //     {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'x-auth-token': localStorage.getItem('token')
  //       },
  //       body: JSON.stringify({
  //         start_date: year + '-01-01',
  //         end_date: year + '-12-31'
  //       })
  //     })
  //     .then(res => {
  //       if(res.status !== 200){
  //         localStorage.clear();
  //         navigate('/')
  //       } else {
  //         return res.json()
  //       }
  //     })
  //     .then(
  //       (result) => {
  //         fetch(hostApi.concat("/invoces/clients"),
  //           {
  //             method: 'POST',
  //             headers: {
  //               'Content-Type': 'application/json',
  //               'x-auth-token': localStorage.getItem('token')
  //             },
  //             body: JSON.stringify({
  //               start_date: beforeYear + '-01-01',
  //               end_date: beforeYear + '-12-31'
  //             })
  //           }).then((res) => {
  //           if (res.status !== 200) {
  //             localStorage.clear();
  //             navigate('/')
  //           } else {
  //             return res.json()
  //           }
  //         }).then(beforeResult => {
  //           setLoad(true);
  //           setTrimester(calculateTrimester(result.map(parseTrimesterResponse), beforeResult.map(parseTrimesterResponse)));
  //         })
  //       },
  //       (error) => {
  //         navigate('/')
  //       }
  //     )
  // }, [])

  useEffect(async() => {
    await requestBenefits(setAnnualValue, 5);
    await requestBenefits(setTrimesterFirstValue, 1);
    await requestBenefits(setTrimesterSecondValue, 2);
    await requestBenefits(setTrimesterThirdValue, 3);
    await requestBenefits(setTrimesterFourthValue, 4);
    setLoad(true);
  }, []);

  // const items  = [
  //   {
  //     label: 'Calcular beneficios/IVA',
  //     key: '1',
  //     icon: <UserOutlined />,
  //   }
  // ];
  //
  // const handleMenuClick = (key) => {
  //   console.log('key', key)
  // }
  //
  // const menuProps = {
  //   items,
  //   onClick: handleMenuClick,
  // };

  const handleClickCard = (nTrimester) => {
    ivaShow[nTrimester-1] = !ivaShow[nTrimester-1];
    setIvaShow(ivaShow);
  }

  // useEffect(() => {
  //   setAnnualValue(calculateAnnual(trimester));
  // }, [trimester])

  if(!load) return <></>

  console.log(annualValue);

  return (
    <div className="site-card-wrapper">
      <Title> Calculo beneficio / IVA Trimestre {year} </Title>
      <Space align="center" direction="vertical">
        <WidgetBars title="Beneficio Anual" data={annualValue.dataPoints}/>
        <Title level={3}> {stringAmount(annualValue.benefits)}€</Title>
      </Space>
      <Space direction="vertical">
        <Space>
          <CardTrimester trimester={1} showIva={ivaShow[0]} dataTrimester={trimesterFirstValue}  /*currentTrimester={currentTrimester}*/ onClick={handleClickCard}/>
          <CardTrimester trimester={2} showIva={ivaShow[1]} dataTrimester={trimesterSecondValue} /*currentTrimester={currentTrimester}*/ onClick={handleClickCard}/>
        </Space>
        <Space>
          <CardTrimester trimester={3} showIva={ivaShow[2]} dataTrimester={trimesterThirdValue} /*currentTrimester={currentTrimester}*/ onClick={handleClickCard}/>
          <CardTrimester trimester={4} showIva={ivaShow[3]} dataTrimester={trimesterFourthValue} /*currentTrimester={currentTrimester}*/ onClick={handleClickCard}/>
        </Space>
      </Space>
    </div>

  );

}

