import { Redirect, Router as Route } from '@reach/router';
import Objective24 from "./pages/Objective2024";
import LoginDemo from "./pages/Login";
import Benefits from "./pages/Benefits";


export default function Router () {
  return (
    <Route>

      <Redirect from="/" to="/login" default noThrow />
      <LoginDemo path="/login" />
      <Objective24 path="/home" year={new Date().getFullYear()}/>
      <Objective24 path="/objective/:year"/>
      <Benefits path="/benefits/:year"/>

    </Route>
  );
};
